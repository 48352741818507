import type { Post } from "~/models/Post"

export const useActualPostStore = defineStore("actualPostStore", {
    state: (): { post: Post|null; loading: boolean } => ({
        post: null,
        loading: false,
    }),
    actions: {
        setPost(post: Post) {
            this.post = post
        },
        async fetchPost(id:string|number) {
            const { get } = useOFetchCustom(`public/posts/details/${id}`)
            this.loading = true
            await get(undefined, {
                onSuccess: ({ data }) => {
                    console.log(data)
                    this.setPost(data)
                },
                onFinish: () => {
                    this.loading = false
                },
            })
        },
    },
})
